<template>

	<div class="cbox">
		<div class="headbar">
			<div style="float: right;">

			</div>
			<div style="padding-top: 14px;">
				<el-breadcrumb>
					<el-breadcrumb-item>基础设置</el-breadcrumb-item>
					<el-breadcrumb-item>字典配置</el-breadcrumb-item>
				</el-breadcrumb>
			</div>

		</div>
		<div class="bcontent">


			<el-row type="flex" justify="start">
				<el-col :span="24">
					<el-tabs v-model="activeName" type="border-card">
						<el-tab-pane label="字典列表" name="dictab">
							<el-table :data="tableData" style="width: 100%;" border stripe>
								<el-table-column type="index" label="序号" width="60px"></el-table-column>
								<el-table-column prop="param_name" label="字典名称">

								</el-table-column>
								<el-table-column prop="param_type" label="字典代码"></el-table-column>
								<el-table-column prop="param_value" label="字典值"></el-table-column>
								<el-table-column prop="param_desc" label="字典详细"></el-table-column>
								<el-table-column prop="sort" label="排序号"></el-table-column>
								<el-table-column label="操作" width="120">
									<template slot-scope="scope">
										<el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消'
											icon="el-icon-info" iconColor="red" @confirm="handleDel(scope.row)"
											placement="left" title="是否确定删除？">
											<a slot="reference" size="small"
												style="cursor: pointer;color: red;font-size: 12px;margin-right: 10px;">删除</a>
										</el-popconfirm>
										<el-button @click="handleModify(scope.row)" type="text" size="small">修改
										</el-button>
                    <el-button @click="handleCopy(scope.row)" type="text" size="small" style="color:#67c23a;">复制
                    </el-button>
									</template>
								</el-table-column>
							</el-table>

						</el-tab-pane>
						<el-tab-pane disabled>
							<div slot="label">
								<el-button size="small" icon="el-icon-plus" @click="formAdd" type="primary">新增
								</el-button>

								<!-- <el-button size="small" icon="el-icon-refresh" @click="copyOther" type="primary">同步
								</el-button> -->
							</div>
						</el-tab-pane>
					</el-tabs>
					<div>




					</div>

				</el-col>





			</el-row>
			<el-dialog :title="modalTitle" :visible.sync="isShowModal" :close-on-click-modal="false"
				:destroy-on-close="true">
				<el-form :model="formData" ref="ruleForm" label-width="120px" :rules="rules"
					style="max-height: 450px;overflow-y: auto;min-height: 300px;">
					<el-form-item label="字典名称：" prop="param_name">
						<el-input v-model.trim="formData.param_name" style="width: 80%" />
					</el-form-item>
					<el-form-item label="字典代码：" prop="param_type">
						<el-input v-model.trim="formData.param_type" style="width: 80%" />
					</el-form-item>
					<el-form-item label="字典值：" prop="param_value">
						<el-input v-model.trim="formData.param_value" style="width: 80%" />
					</el-form-item>

					<el-form-item label="字典详细/说明：" prop="param_desc">
						<el-input v-model.trim="formData.param_desc" style="width: 80%" />
					</el-form-item>

					<el-form-item label="是否启用" prop="param_desc">
						<el-select v-model="formData.used">
							<el-option label="启用" :value="0"></el-option>
							<el-option label="禁用" :value="1"></el-option>

						</el-select>
					</el-form-item>

					<el-form-item label="排序：" prop="sort">
						<el-input-number :min="0" v-model.trim="formData.sort" placeholder="请输入排序号(数字)"
							controls-position="right" />
					</el-form-item>
				</el-form>
				<el-row>
					<el-col :span="22" style="text-align: right;">
						<el-button size="small" type="default" @click="isShowModal = false">取消</el-button>
						<el-button size="small" type="primary" @click="submitForm('ruleForm')">保存</el-button>
					</el-col>
				</el-row>
			</el-dialog>




		</div>
	</div>
</template>
<script>
import api from '@/api/api';

export default {
	name: 'deptRole',
	data() {
		return {
			isShowModal: false,
			modalTitle: '添加',
			formData: { used: 1 },
			//table
			activeName: 'dictab',
			activeName1: "valuetab",
			tabMapOptions1: {
				label: ""
			},
			tableData: [],
			rules: {
				param_name: [{
					required: true,
					message: '请输入字典名称',
					trigger: 'blur'
				}],
				param_type: [{
					required: true,
					message: '请输入字段名',
					trigger: 'blur'
				}],
				param_value: [{
					required: true,
					message: '请设置字典值',
					trigger: 'blur'
				}],
				sort: [{
					required: true,
					message: '请输入排序号',
					trigger: 'blur'
				}],
			},
			tableData2: [],
			param_type: '',
		}
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList() {

			this.$http.post("/api/sys_param_list",).then(res => {
				this.tableData = res.data


			})
		},

		formAdd() {
			this.isShowModal = true;
			this.formData = {
				param_value: "",
				sort: 99,
				used: 0
			}
			this.modalTitle = '添加'
		},
		copyOther() {
			let target_pmid = 101
			this.$confirm(`此操作将生成默认字典，当前拷贝学校id为:${target_pmid},确认初始化?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {

				this.$http.post("/api/sys_dictionary_copy", {
					target_pmid: target_pmid
				}).then(ok => {
					this.$message.success('拷贝成功')
					this.getList()
				})

			}).catch(() => {

			});


		},
		handleModify(row) {
			this.isShowModal = true;
			this.modalTitle = '修改';


			this.formData = {
				...row
			}
		},
		handleDel(row) {
			this.$http.post('/api/sys_param_delete', {
				id: row.id
			}).then(res => {
				this.$message.success('删除成功')
				this.getList();
			})
		},
    handleCopy(row){
      this.isShowModal = true;
      this.modalTitle = '新增';
      delete row.id
      this.formData = {
        ...row
      }
    },
		addDept() {
			let _this = this
			if (this.formData.param_value && this.formData.param_value.length > 0) {
				this.formData.param_value.push({
					id: null,
					value: ''
				})
			} else {
				this.formData.param_value = []
				this.formData.param_value.push({
					id: null,
					value: ''
				})
			}
			console.log(this.formData.param_value)

			this.formData = {
				..._this.formData
			}

		},
		delDept(index) {
			let _this = this
			this.formData.param_value.splice(index, 1)
			this.formData = {
				..._this.formData
			}
		},

		submitForm(formName) {
			let _this = this
			this.$refs[formName].validate((valid) => {
				if (valid) {
					let params = {
						..._this.formData
					}
					params.del_flag = 0;

					this.$http.post("/api/sys_param_edit", params).then(res => {
						this.$message.success('保存成功')
						this.getList();
						this.isShowModal = false;
					})
				}
			})
		},
		showValues(row) {
			let _this = this
			this.param_type = row.param_type
			this.tabMapOptions1.label = row.param_desc

			console.log(this.param_type)
			this.tableData2 = []
			if (Array.isArray(row.param_value)) {
				this.tableData2 = row.param_value
			} else if (row.param_value != '') {
				let arr = JSON.parse(row.param_value)
				this.tableData2 = arr
			}
		},
	}
}
</script>
